document.addEventListener("turbolinks:load", () => {
  let internal_btn = document.querySelector("#lb_internal");
  var ethic_btn = document.querySelector("#lb_ethic_committee")
  if (internal_btn) {
    internal_btn.addEventListener("click", (event) => {
      document.querySelector("#lb_internal").classList.add("active");
      document.querySelector("#lb_denunciator").classList.remove("active");
      if(ethic_btn !== null){
        document.querySelector("#lb_ethic_committee").classList.remove("active");
        document.querySelector(
          "#more_information_info_type_ethic_committee"
        ).checked = false;
      }
      document.querySelector(
        "#more_information_info_type_denunciator"
      ).checked = false;
      document.querySelector(
        "#more_information_info_type_internal"
      ).checked = true;
    });
  }
  if (document.querySelector("#lb_ethic_committee")) {
    document
      .querySelector("#lb_ethic_committee")
      .addEventListener("click", (event) => {
        document.querySelector("#lb_ethic_committee").classList.add("active");
        if (internal_btn) {
          internal_btn.classList.remove("active");
          document.querySelector(
            "#more_information_info_type_internal"
          ).checked = false;
        }
        document.querySelector("#lb_denunciator").classList.remove("active");
        document.querySelector(
          "#more_information_info_type_denunciator"
        ).checked = false;
        document.querySelector(
          "#more_information_info_type_ethic_committee"
        ).checked = true;
      });

    document
      .querySelector("#lb_denunciator")
      .addEventListener("click", (event) => {
        document.querySelector("#lb_denunciator").classList.add("active");
        if (internal_btn) {
          internal_btn.classList.remove("active");
          document.querySelector(
            "#more_information_info_type_internal"
          ).checked = false;
        }
        document
          .querySelector("#lb_ethic_committee")
          .classList.remove("active");
        document.querySelector(
          "#more_information_info_type_ethic_committee"
        ).checked = false;
        document.querySelector(
          "#more_information_info_type_denunciator"
        ).checked = true;
      });
  }

  if (document.querySelector("#lb_internal")) {
    document
      .querySelector("#lb_denunciator")
      .addEventListener("click", (event) => {
        document.querySelector("#lb_denunciator").classList.add("active");
        if (internal_btn) {
          internal_btn.classList.remove("active");
          document.querySelector(
            "#more_information_info_type_internal"
          ).checked = false;
        }
        if(ethic_btn !== null){
          document
          .querySelector("#lb_ethic_committee")
          .classList.remove("active");  
          document.querySelector(
            "#more_information_info_type_ethic_committee"
          ).checked = false;
        }
        document.querySelector(
          "#more_information_info_type_denunciator"
        ).checked = true;
      });
  }

  let showContentLink = document.querySelector("p.show-content");

  if (showContentLink) {
    document.querySelector("p.show-content + div.trix-content").classList.toggle("hide");

    showContentLink.addEventListener("click", () => {
      document.querySelector("p.show-content + div.trix-content").classList.toggle("hide");
      showContentLink.classList.toggle("mb-0");
      showContentLink.querySelector("span:last-child").classList.toggle("d-none");
      showContentLink.querySelector("span:first-child").classList.toggle("d-none");
    });
  }
});
